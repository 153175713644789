@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tomo-wrapper {
  padding: 12px;
  margin-top: 36px;
}

.tomo-content {
  margin: 24px 0;
}

.tomo-title {
  color: var(--Eerie-black, #1e1e20);
  font-family: "SofiaProSemiBold";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}

.tomo-desc {
  color: var(--Eerie-black, #1e1e20);
  font-family: "SofiaProRegular";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.download-now {
  color: var(--1-f-2024, #1f2024);
  font-family: "SofiaProMedium";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 24px;
  margin-bottom: 10px;
}

.download-btn {
  padding: 10px;
  border-radius: 8px;
  background: var(--1-f-2024, #1f2024);
  width: calc(100vw - 46px);
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
/* custom-styles.css */

.only-dark {
    display: none;
}

.only-light {
    display: block;
}

@media (prefers-color-scheme: dark) {
    .only-dark {
        display: block;
    }
    .only-light {
        display: none;
    }
}

.count-cur,.count-prev{
  transition: top 0.2s ease-in-out;
}